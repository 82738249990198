import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Tree, TreeNode } from 'react-organizational-chart';

const OrgChart = () => {
  const intl = useIntl();
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  useEffect(() => {
    const storedMode = localStorage.getItem('selected_mode');
    if (storedMode) {
      const parsedMode = JSON.parse(storedMode); // Parse the JSON string
      const isDarkModeActive = parsedMode.selectedMode === 'dark';
      setIsDarkMode(isDarkModeActive);
    }
  }, []);

  const lineColor = isDarkMode ? '#666666' : '#3E037C';
  return (
    <>
      <Row style={{ justifyContent: 'center', margin: '20px 0' }}>
        <Tree
          lineWidth={'3px'}
          lineColor={lineColor}
          lineBorderRadius={'30px'}
          lineStyle={'dashed'}
          label={
            <div className="ceo-label">
                          <div> {intl.formatMessage({ id: 'Chief Executive Officer' })}</div>
                          <div className='py-1'> {intl.formatMessage({ id: 'CEO' })}</div>
            </div>
          }
        >
          <div className='hhh'>
          <TreeNode
            label={
              <div className="pmo-label">
              <div> {intl.formatMessage({ id: 'Project Management Office' })}</div>
               <div className='py-1'>{intl.formatMessage({ id: 'PMO' })}</div> 
              </div>
            }
          >
            
            
            
            <TreeNode
              label={
                <div className="second-level-label">
                  {intl.formatMessage({ id: 'Implementation' })}
                </div>
              }
            >
              
              {/* Additional child nodes */}
            </TreeNode>
            
           
            <TreeNode
              label={
                <div className="second-level-label">
                  {intl.formatMessage({ id: 'Product' })}
                </div>
              }
            >
              {/* Additional child nodes */}
            </TreeNode>
            
          </TreeNode>
          </div>
          <div className='ccc'>
          <TreeNode
            label={
              <div className="Administrative-label">
                {intl.formatMessage({ id: 'Administrative affairs' })}
              </div>
            }
          >
            <TreeNode
              label={
                <div className="second-level-label">
                  {intl.formatMessage({ id: 'HR' })}
                </div>
              }
            />
            <TreeNode
              label={
                <div className="second-level-label">
                  {intl.formatMessage({ id: 'Financial' })}
                </div>
              }
            />
          </TreeNode>
          </div>
        </Tree>

        <div className="sub-level">
          <Tree
            lineWidth={'3px'}
             lineColor={lineColor} 
            lineStyle={'dashed'}
            lineBorderRadius={'30px'}
            label={<div></div>}
          >
            <TreeNode
              label={
                <div className="sub-level-label">
                  {intl.formatMessage({ id: 'Outsource' })}
                </div>
              }
            />
            <TreeNode
              label={
                <div className="sub-level-label">
                  {intl.formatMessage({ id: 'Monitoring' })}
                </div>
              }
            />
            <TreeNode
              label={
                <div className="sub-level-label">
                  {intl.formatMessage({ id: 'Service' })}
                </div>
              }
            />
          </Tree>
        </div>
      </Row>
      <img src="/media/assets/icons/union-red.svg" alt="star-icon" className="union-hirarcky-red"></img>
      <img src="/media/assets/icons/union-gradient-blue.svg" alt="star-icon" className="union-hirarcky-blue"></img>
      <img src="/media/assets/icons/union-group-2.svg" alt="star" className="union-group-hirarcky d-inline" />
      <Row style={{ justifyContent: 'center', margin: '20px 0' }}>
        <Tree
          lineWidth={'3px'}
           lineColor={lineColor} 
          lineStyle={'dashed'}
          lineBorderRadius={'10px'}
          label={<div></div>}
        >
          <div className='kkk'>
          <TreeNode
            label={
              <div className="second-tree-label"  style={{margin:"0 22px"}}>
                {intl.formatMessage({ id: 'Delivery Manager' })}
              </div>
            }
          >
            <TreeNode
              label={
                <div className="last-level-label">
                  {intl.formatMessage({ id: 'Quality Control' })}
                </div>
              }
            />
            <TreeNode
              label={
                <div className="last-level-label">
                  {intl.formatMessage({ id: 'Product Owner' })}
                </div>
              }
            />
          </TreeNode>
          </div>
          <div className='kkk'>
          <TreeNode
            label={
              <div className="operation-label second-tree-label">
                {intl.formatMessage({ id: 'Operation Manager' })}
              </div>
            }
          />
 </div>
 <div className='kkk'>
          <TreeNode
            label={
              <div className="second-tree-label">
                {intl.formatMessage({ id: 'Research & Development Manager' })}
              </div>
            }
          >
            <TreeNode
              label={
                <div className="last-level-label">
                  {intl.formatMessage({ id: 'UI-UX Manager' })}
                </div>
              }
            />
             <TreeNode
              label={
                <div className="last-level-label">
                  {intl.formatMessage({ id: 'pilot projects' })}
                </div>
              }
            />
            <TreeNode
              label={
                <div className="last-level-label">
                  {intl.formatMessage({ id: 'BA Manager' })}
                </div>
              }
            />
          </TreeNode>
          </div>
          <div className='kkk'>
          <TreeNode
            label={
              <div className="second-tree-label">
                {intl.formatMessage({ id: 'Business Development Manager' })}
              </div>
            }
          >
            <TreeNode
              label={
                <div className="last-level-label">
                  {intl.formatMessage({ id: 'Sales' })}
                </div>
              }
            />
            <TreeNode
              label={
                <div className="last-level-label">
                  {intl.formatMessage({ id: 'Marketing' })}
                </div>
              }
            />
          </TreeNode>
          </div>
        </Tree>
      </Row>

      <Row className="unique-tree3-row" style={{ justifyContent: 'center', margin: '20px 0' }}>
        <Tree
          lineWidth={'3px'}
           lineColor={lineColor} 
          lineStyle={'dashed'}
          lineBorderRadius={'10px'}
          label={<div></div>}
        >
          <TreeNode
            label={
              <div className="final-level-label">
                {intl.formatMessage({ id: 'Infrastructure' })}
              </div>
            }
          />
          <TreeNode
            label={
              <div className="final-level-label" style={{ padding: '6px 10px' }}>
                {intl.formatMessage({ id: 'Smart Solution Manager' })}
              </div>
            }
          />
          <TreeNode
            label={
              <div className="final-level-label">
                {intl.formatMessage({ id: 'Web App Manager' })}
              </div>
            }
          />

          <TreeNode
            label={
              <div className="final-level-label">
                {intl.formatMessage({ id: 'Frontend Manager' })}
              </div>
            }
          />
          <TreeNode
            label={
              <div className="final-level-label">
                {intl.formatMessage({ id: 'Mobile App Manager' })}
              </div>
            }
          />
        </Tree>
      </Row>
    </>
  );
};

export default OrgChart;
